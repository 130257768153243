<template>
<AppLayout>
    <template v-slot:appContent>
        <section class="users-list-wrapper">
            <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
                <h4 class="">Order Jobs / List</h4>

            </div>
            <div class="users-list-filter px-1 my-2 py-2 border rounded">
                <div class="row align-items-center">
                    <div class="col-12 col-sm-12 col-md-3 ">
                        <div class="form-group">
                            <label>Order No </label>
                            <input v-model="getOrderJobsParams.order_no" class="form-control " type="text" placeholder="Enter code">
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-3">
                        <div class="form-group">
                            <label>Job status</label>
                            <VueMultiselect v-model="selectedJobStatus" :options="jobStatusOptions" :close-on-select="true" label="name" track-by="value" :show-labels="false" :allow-empty="false" />
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-3">
                        <div class="form-group">
                            <label>Assign To </label>
                            <VueMultiselect v-model="selectedAssignee" :options="technicianList" :close-on-select="true" label="name" track-by="value" :show-labels="false" :allow-empty="false" />
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-3">
                        <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0" @click="applyOrderJobsFilter(null)">Filter
                        </button>
                    </div>

                    <!-- <div class="col-12 col-sm-12 col-md-4">
                            <label>Status</label>
                            <VueMultiselect :allow-empty="false" v-model="selectedStatus" class="" :options="status" :close-on-select="true" placeholder="Select status" label="name" track-by="value" :show-labels="false" />
                        </div> -->
                </div>
                <!-- <div class="row pt-2 d-flex justify-content-end">
                        <div class="col-12 col-sm-12 col-md-3">
                            <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0" @click="applyOrderJobsFilter(null)">Filter
                            </button>
                        </div>
                    </div> -->
            </div>
            <div class="users-list-table">
                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive" ref="serviceListRef">
                            <table id="users-list-datatable" class="table">
                                <thead>
                                    <tr>
                                        <th class="position-relative" :style="{ width: '10%' }">SL</th>
                                        <th class="position-relative" :style="{ width: '20%' }">Order No</th>
                                        <th class="position-relative" :style="{ width: '20%' }">Customer</th>
                                        <th class="position-relative" :style="{ width: '10%' }">Job status</th>
                                        <th class="position-relative" :style="{ width: '20%' }">Assign To <br>Request To</th>
                                        <th class="position-relative" :style="{ width: '10%' }">Order status </th>

                                        <th class="position-relative" :style="{ width: '10%' }">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(orderjob, index) in orderJobs" :key="index">
                                        <td>
                                            {{ index + 1 }}
                                        </td>

                                        <td>
                                            {{ orderjob?.order?.order_no ?? '' }}
                                            
                                        </td>
                                      <td>
                                        <router-link title="Click to view customer details"
                                                     :to="{name:'appCustomerView',params: { id: orderjob.customer.id }}"
                                                     class="text-capitalize">{{ orderjob?.customer?.user?.full_name }}
                                        </router-link>
                                        <br>
                                        <span class="text-light">{{ buildAddress(orderjob.customer.address) }}</span>
                                      </td>
                                        <td>
                                            <div>

                                                <span :class="`badge ${jobStatusColor(orderjob.status)}`" > {{ orderjob.status }} </span>    
                                            </div>
                                            
                                        </td>

                                        <td>
                                            {{ orderjob?.assignedTo?.full_name ?? 'Not Assigned' }}
                                        </td>
                                        <td>
                                            {{ orderjob?.order?.status ?? '' }}
                                        </td>
                                        <td>
                                            <div class="d-flex align-items-start justify-content-center">

                                                <div class="dropdown">
                                                    <span type="button" class="px-0 py-0 view-edit-delete-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="bx bx-dots-vertical-rounded h-100"></i>
                                                    </span>
                                                    <div class="dropdown-menu">

                                                        <button class="dropdown-item" @click="openUpdateStatusModal(orderjob.id)">
                                                            Update Status
                                                        </button>
                                                        <button class="dropdown-item" @click="openUpdateAssigneeModal(orderjob.id)">
                                                            Update Assigne
                                                        </button>
                                                        <button class="dropdown-item" @click="openOrderJobNoteCreateModal(orderjob.id)">
                                                            Add Note
                                                        </button>
                                                        <button class="dropdown-item" @click="openJobNotesViewModal(orderjob.id)">
                                                            View Notes
                                                        </button>
                                                        <button class="dropdown-item" @click="openJobTransferHistoryModal(orderjob.id)">
                                                          View Transfer History
                                                        </button>

                                                    </div>
                                                </div>

                                            </div>
                                        </td>

                                        <!-- <td>
                                            <div class="d-flex align-items-top justify-content-around">
                                                <div>
                                                    <i @click="viewMessagesModalOpen(orderjob)" title="view messages" class='bx bx-note cursor-pointer text-warning'></i>
                                                </div>
                                                <div>
                                                    <i @click="addMessageModalOpen(orderjob)" class='bx bx-comment-add text-success cursor-pointer' title="add message"></i>

                                                </div>

                                            </div>
                                        </td> -->
                                    </tr>
                                </tbody>
                            </table>
                            <ListPagination position="right" :pagination-data="paginateLinks" @getClickedPage="applyOrderJobsFilter" />
                        </div>
                    </div>
                </div>

                <div class="" data-toggle="modal" data-target="#updateAssigneeModal"></div>
                <div class="" data-toggle="modal" data-target="#orderJobNoteCreateModal"></div>
                <div class="" data-toggle="modal" data-target="#viewJobNotesModal"></div>
                <div class="" data-toggle="modal" data-target="#updateOrderJobStatusModal"></div>
                <div class="" data-toggle="modal" data-target="#jobTransferHistoryModal"></div>

                <AddOrderJobNote :currentOrderJob="currentOrderJob" @getOrderJobsList="getOrderJobsList" />
                <UpdateAssigneeModal @getOrderJobsList="getOrderJobsList" />
                <UpdateOrderJobStatusModal @getOrderJobsList="getOrderJobsList" />
                <ViewJobNotesModal />
                <JobTransferHistoryModal :order-job="currentOrderJob"/>

            </div>
        </section>
    </template>
</AppLayout>
</template>

<script>
// components
import AppLayout from "@/layouts/backEnd/AppLayout.vue";
import ListPagination from "@/components/backEnd/pagination/ListPagination";
import AddOrderJobNote from "@/views/backEnd/orderJobs/includes/AddOrderJobNote";
import ViewJobNotesModal from "@/views/backEnd/orderJobs/includes/ViewJobNotesModal";
import UpdateAssigneeModal from "@/views/backEnd/orderJobs/includes/UpdateAssigneeModal";
import UpdateOrderJobStatusModal from "@/views/backEnd/orderJobs/includes/UpdateOrderJobStatusModal";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Authorization from "@/components/backEnd/mixins/Authorization";
import Loader from "@/components/backEnd/mixins/Loader";
// core packages
import {
    mapActions,
    mapGetters
} from "vuex";
import AddressMixin from "@/components/backEnd/mixins/AddressMixin";
import JobTransferHistoryModal from "@/views/backEnd/orderJobs/includes/JobTransferHistoryModal.vue";

export default {
    name: "OrderJobsList",
    mixins: [ShowToastMessage, Loader, Authorization, AddressMixin],
    components: {
      JobTransferHistoryModal,
        AppLayout,
        AddOrderJobNote,
        ListPagination,

        ViewJobNotesModal,
        UpdateAssigneeModal,
        UpdateOrderJobStatusModal
    },
    data() {
        return {
            getUserTechniciansParams: {
                where_has_technician_status: 1,
                with_relation: ['technician'],
                order_by_first_name: "ASC",
            },
            currentOrderJob: {},
            selectedJobStatus: {
                value: '',
                name: "Any",
            },

            selectedStatus: {
                value: '',
                name: "Any",
            },
            selectedAssignee: {
                value: '',
                name: "Any",
            },

            selectedPagination: {
                value: '',
                name: 'Default'
            },
            getOrderJobsParams: {
                with_relation: ['order', 'customer.address','customer.user', 'assignedTo', 'orderJobNotes', 'jobTransfers','jobTransfers.assigner','jobTransfers.assignee'],
                order_by_date: 'ASC',
                paginate: 1,
                pagination: '',
                page: '',
                order_by_id: 'DESC',
                order_no: '',
                status: null,
                assigned_to: null,

            },

            getSettingsParams: {
                type: ["default", "order"],
                key: ["default_pagination", "order_job_status"],
            },

        };
    },
    computed: {
        ...mapGetters({
            // orderJobNote
            orderJobNotes: 'appOrderJobNotes/orderJobNotes',
            orderJobs: 'appOrderJobs/orderJobs',
            paginateLinks: "appOrderJobs/paginateLinks",

            settingOrderJobStatus: 'appSettings/settingOrderJobStatus',

        }),
        paginationList() {
            return [{
                value: this.$store.getters['appSettings/settingDefaultPagination'].value,
                name: 'Default'
            }, {
                value: 25,
                name: '25 Entries'
            }, {
                value: 50,
                name: '50 Entries'
            }, {
                value: 100,
                name: '100 Entries'
            }];
        },

        jobStatusOptions() {
            return [{
                value: null,
                name: "Any",
            }, ...this.settingOrderJobStatus.value];
        },
       
        technicianList() {

            let technicians = this.$store.getters['appUsers/users'].map(item => {
                let technicianUserId = item.id;
                let firstName = item.first_name;
                let lastName = item.last_name;
                let fullName = firstName;
                fullName += firstName ? ` ${lastName}` : lastName;
                return {
                    value: technicianUserId,
                    name: fullName,
                };
            })
            return [{
                value: null,
                name: "Any"
            }, ...technicians];
        }

    },
    watch: {
        selectedPagination(selectedPagination) {
            this.getOrderJobsParams.pagination = selectedPagination.value;
        },

    },

    methods: {
        ...mapActions({

            getOrderJobs: 'appOrderJobs/getOrderJobs',
            getOrderJobNotes: 'appOrderJobNotes/getOrderJobNotes',
            getUserType: 'appUsers/getUserType',

            getUsers: 'appUsers/getUsers',

            // settings
            getSettings: "appSettings/getSettings",

        }),

      async openJobTransferHistoryModal(orderJobId) {
        await this.setCurrentOrderJob(orderJobId)
        await this.$nextTick(() => {
          document.querySelector(`[data-target="#jobTransferHistoryModal"]`).click();
        });

      },

        jobStatusColor(color){
           
            switch (color) {
                case 'In Progress': 
                    return 'badge-warning';    
                case 'Processing': 
                    return 'badge-primary';
                case 'In Lab':
                    return 'badge-info';
                case 'Ready':
                    return 'badge-secondary';
                case 'Completed':
                    return 'badge-success';
                case 'Closed':
                    return 'badge-success';            
                default:
                    return 'badge-primary';
                 
            } 
        },
        async openJobNotesViewModal(id) {
            this.loader(true);
            const response = await this.getOrderJobNotes({
                order_job_id: id,
                with_relation: ['user']
            });
            if (response ?.message) {
                this.showToastMessage(response);
            }
            this.loader(false);
            if (this.orderJobNotes.length > 0) {
                document.querySelector('[data-target="#viewJobNotesModal"]').click();
                return;
            }
            this.showToastMessage({
                message: 'No notes found',
                type: 'warning'
            });

        },
        async setCurrentOrderJob(id) {
            this.currentOrderJob = {};
            this.currentOrderJob = this.orderJobs.find(orderJob => orderJob.id === id);
        },
        async openUpdateAssigneeModal(id) {
            await this.setCurrentOrderJob(id);
            this.$store.commit('appOrderJobs/RESET_ORDER_JOB');
            this.$nextTick(() => {
                this.$store.commit('appOrderJobs/SET_ORDER_JOB', this.currentOrderJob);
                this.$nextTick(() => {
                    document.querySelector('[data-target="#updateAssigneeModal"]').click();
                });
            });

        },
        async openUpdateStatusModal(id) {
            await this.setCurrentOrderJob(id);
            this.$store.commit('appOrderJobs/RESET_ORDER_JOB');
            this.$nextTick(() => {
                this.$store.commit('appOrderJobs/SET_ORDER_JOB', this.currentOrderJob);
                this.$nextTick(() => {
                    document.querySelector('[data-target="#updateOrderJobStatusModal"]').click();
                });
            });

        },

        async openOrderJobNoteCreateModal(id) {

            await this.setCurrentOrderJob(id);
            this.$nextTick(() => {
                document.querySelector('[data-target="#orderJobNoteCreateModal"]').click();
            });

        },
        async getCurrentUserTypes(id) {
            await this.getUserType(id);
        },
        async getSettingList() {
            this.loader(true);
            const response = await this.getSettings(this.getSettingsParams);
            this.loader(false);
            if (response.message) {
                this.showToastMessage(response);
            }
        },

        async getOrderJobsList() {
            this.loader(true);
            const response = await this.getOrderJobs(this.getOrderJobsParams);
            this.loader(false);

            if (response.message) {
                this.showToastMessage(response);
            }

        },

        async applyOrderJobsFilter(pageNumber) {
            if (this.selectedJobStatus.value) {
                this.getOrderJobsParams.status = this.selectedJobStatus.value;
            }
            if (this.selectedAssignee.value) {
                this.getOrderJobsParams.assigned_to = this.selectedAssignee.value;
            }

            if (this.getOrderJobsParams.order_no.length) {
                this.getOrderJobsParams.status = null;
                this.selectedJobStatus = {
                    value: null,
                    name: "Any"
                };
                this.getOrderJobsParams.assigned_to = null;
                this.selectedAssignee = {
                    value: null,
                    name: "Any"
                };

            }
            this.getOrderJobsParams.page = pageNumber;
            await this.getOrderJobsList();
        },

        async getUserTechnicianList() {
            await this.getUsers(this.getUserTechniciansParams).then(async response => {
                if (response.status !== 200) {
                    await this.showToastMessage(response.message);
                }
            });
        },

    },

    async mounted() {
        this.loader(true);
        await this.getCurrentUserTypes(this.$store.getters["appAuthLogin/authUser"].id);
        await this.getSettingList();
        await this.getOrderJobsList();
        await this.getUserTechnicianList();

    },

    beforeMount() {
        // this.resetServices();
    },

};
</script>

<style scoped>
    </style>
