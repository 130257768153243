<script>
export default {
  name: "JobTransferHistoryModal",
  props: ['orderJob'],
  data() {
    return {
      jobTransferHistories: [],
      orderNo: '',
    }
  },
  watch: {
    orderJob(currentValue) {
      this.jobTransferHistories = currentValue?.jobTransfers;
      this.orderNo = currentValue.order_no;
    }
  }
}
</script>

<template>
  <div class="modal fade " id="jobTransferHistoryModal" tabindex="-1" role="dialog"
       aria-labelledby="productDetailsModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Job Transfer History</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row pt-1">
              <div class="col-12">
                <div class="table-responsive" v-if="jobTransferHistories.length > 0">
                  <table class="table table-nowrap card-table">
                    <thead class="thead-light">
                    <tr>
                      <th>REF. ID</th>
                      <th>FROM TECHNICIAN</th>
                      <th>TO TECHNICIAN</th>
                      <th>STATUS</th>
                      <th>PLATFORM</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(jobTransferHistory,index) in jobTransferHistories" :key="index">
                      <td>{{ orderNo }}</td>
                      <td>
                        {{
                          jobTransferHistory?.assigner?.id ? `${jobTransferHistory.assigner.full_name}` : 'Not Assigned'
                        }}
                        <br><span>{{ jobTransferHistory?.requested_at }}</span>
                      </td>
                      <td>{{
                          jobTransferHistory?.assignee?.id ? `${jobTransferHistory.assignee.full_name}` : 'Not Assigned'
                        }}
                        <br><span>{{ jobTransferHistory?.confirmed_at }}</span>
                      </td>
                      <td>

                        <span v-if="jobTransferHistory.status === 'Pending'"
                              class="badge badge-light-warning badge-pill text-capitalize">
                            {{
                            jobTransferHistory.status
                          }}
                        </span>
                        <span v-else-if="jobTransferHistory.status === 'Accepted'"
                              class="badge badge-light-success badge-pill text-capitalize">
                                                    {{
                            jobTransferHistory.status
                          }}
                                                </span>
                        <span v-else-if="jobTransferHistory.status === 'Declined'"
                              class="badge badge-light-danger badge-pill text-capitalize">
                                                    {{
                            jobTransferHistory.status
                          }}
                        </span>

                      </td>
                      <td>{{ jobTransferHistory.panel }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else>
                  <h1 class="text-center py-2 text-secondary mb-0">No History Found !</h1>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="modal-footer border-0 py-1">
          <button type="button" class="btn btn-primary" data-dismiss="modal">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Close</span>
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>