<template>
<div class="modal fade" id="updateAssigneeModal" tabindex="-1" role="dialog" aria-labelledby="updateAssigneeModal" aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Update Assignee</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                  <div class="row">
                      <div class="col-12 col-md-8 col-lg-8">
                          <div class="form-group control">
                              <label>Assign To</label>
                              <VueMultiselect v-model="selectedAssignee" class="" placeholder="Select assignee" :options="assigneeOptions" :close-on-select="true" label="name" track-by="value" :show-labels="false" :allow-empty="false" />
                          </div>
                      </div>
                    <div class="col-12 col-md-4 col-lg-4">
                      <div class="form-group">
                        <div class="controls">
                          <label>With Force</label>
                          <div style="margin-top: .5rem">
                            <div
                                class="custom-control custom-switch custom-switch-success custom-control-inline mb-1 d-flex align-items-center">
                              <div>
                                <input v-model="checkBoxStatus" type="checkbox" class="custom-control-input"
                                       id="current_status">
                                <label class="custom-control-label mr-1" for="current_status">
                                  <span class="switch-icon-left"><i class="bx bx-check"></i></span>
                                  <span class="switch-icon-right"><i class="bx bx-x"></i></span>
                                </label>
                              </div>
                              <span class="font-medium-1 text-bold-600">{{
                                  checkBoxStatus === true ? "Yes" : "No"
                                }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6 class="text-danger"><i>Note: </i></h6>
                    </div>
                  </div>
            </div>
            <div class="modal-footer border-0 pt-0">

              <button type="button" class="btn btn-primary" @click="updateAssignee">
                <i class="bx bx-x d-block d-sm-none"></i>
                <span class="d-none d-sm-block">{{ checkBoxStatus === true ? "Update" : "Send Request" }}</span>
              </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import GlobalMixin from "@/components/backEnd/mixins/GlobalMixin";

export default {
    name: "updateAssigneeModal",
    mixins: [ShowToastMessage, Loader, GlobalMixin],
    props: ['order'],
    emits: ['getOrderJobsList'],

    data() {
        return {
            assigneeOptions: [],
            selectedAssignee: null,
            checkBoxStatus: false,
        }
    },
    computed: {
        ...mapGetters({
            orderJob: 'appOrderJobs/orderJob',
        }),

    },
    watch: {
        orderJob(currentOrderJob) {
            if (currentOrderJob ?.id) {
                this.resetData();
                this.assigneeOptions = this.getTechnicianList().filter(item => item.value !== currentOrderJob ?.assignedTo ?.id);
            }

        }
    },
    methods: {
        ...mapActions({
            putOrderJob: 'appOrderJobs/putOrderJob',
            postJobTransferRequest: 'appJobTransfers/postJobTransferRequest',
            postJobTransfer: 'appJobTransfers/postJobTransfer'
        }),
        getTechnicianList() {
            return this.$store.getters['appUsers/users'].map(item => {
                let technicianUserId = item.id;
                let firstName = item.first_name;
                let lastName = item.last_name;
                let fullName = firstName;
                fullName += firstName ? ` ${lastName}` : lastName;
                return {
                    value: technicianUserId,
                    name: fullName,
                };
            })
        },
        async sendPostJobTransfer(){
          const data = {
            'order_job_id' : this.orderJob.id,
            'from_user_id' : this.orderJob.assignedTo.id, // user id
            'to_user_id'   : this.selectedAssignee.value, // technician user id
            'panel'        :  1, // system web app
            'status'        : 2, // forcefully accept request
            'requested_at'  : this.getDateTime(new Date()),
            'confirmed_at'  : this.getDateTime(new Date()),
          }
           await this.postJobTransfer(data).then(async response => {
            return response.status === 200
           })
        },
        async sendJobTransferRequest(){
          const data = {
            'order_job_id' : this.orderJob.id,
            'from_user_id' : this.orderJob.assignedTo.id, // user id
            'to_user_id'   : this.selectedAssignee.value, // technician user id
            'panel'        :  1 // system web app
          }
          this.loader(true);
          await this.postJobTransferRequest(data).then(response => {
            this.loader(false);
            if (response.message) {
               this.showToastMessage(response.message);
            }
            if(response.status === 200){
              this.showToastMessage({
                type: 'success',
                message: 'Job transfer request sent successfully'
              });
            }
          });
        },
        async updateAssignee() {
            if (!this.selectedAssignee ?.name) {
                this.showToastMessage({
                    type: 'warning',
                    message: 'Please select assignee'
                });
                return;
            }
            if(!this.checkBoxStatus){
              await this.sendJobTransferRequest();
              this.$emit('getOrderJobsList');
              document.querySelector('[data-target="#updateAssigneeModal"]').click();
              this.resetData();

            }else{
              const dataObj = {
                id: this.orderJob.id,
                data:{
                  assigned_to: this.selectedAssignee.value,
                }
              }
              this.loader(true);
              await this.sendPostJobTransfer();
              const response = await this.putOrderJob(dataObj);
              this.loader(false);
              if (response.message) {
                this.showToastMessage(response);
              }
              if (response.status === 200) {
                this.showToastMessage({
                  type: 'success',
                  message: 'Updated successfully'
                });
                this.$emit('getOrderJobsList');
                document.querySelector('[data-target="#updateAssigneeModal"]').click();
                this.resetData();
              }
            }



        },
        resetData() {
            this.selectedAssignee = null;
            this.checkBoxStatus = false;

        }

    }

}
</script>

<style>
</style>
